@tailwind base; /* Preflight will be injected here */
@tailwind components;
@tailwind utilities;
@import './fonts.css';

@layer base {
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  body {
    @apply bg-stone-100;
    @apply min-h-screen;
  }
}